@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;0,700;0,900;1,400;1,500;1,700;1,900&display=swap');
@import "./mixin";

html,
body {
    padding: 0;
    margin: 0;

    font-family: "Roboto", sans-serif;
}

a {
    text-decoration: none;
}

* {
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
}

.container {
    @include container;
}

h1 {
    @include title1;
}

h2 {
    @include title2;
}

h3 {
    @include title3;
}

h4 {
    @include title4;
}

h5 {
    @include title5;
}

h6 {
    @include title6;
}
